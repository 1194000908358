<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-11">
        <div class="pb-5 px-2 px-lg-4">
          <VForm ref="form" v-slot="{ errors }" @submit="requestOrder">
            <p class="my-6 text-info fw-light">
              親愛的客戶 您好：
              若您對仁大的產品、服務內容，有任何的疑問或建議，請留下您的聯絡資訊及寶貴的意見。
            </p>
            <div class="row gx-lg-5 justify-content-center">
              <div class="mb-3 mb-lg-5 col-12">
                <label for="name" class="form-label">全名<span class="text-primary">*</span></label>
                <VField
                  id="name"
                  name="全名"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors['全名'] }"
                  placeholder="請輸入全名"
                  rules="required"
                  v-model="form.name"
                />
                <ErrorMessage name="全名" class="invalid-feedback" />
              </div>
              <div class="mb-3 mb-lg-5 col-12">
                <label for="company" class="form-label"
                  >公司<span class="text-primary">*</span></label
                >
                <VField
                  id="company"
                  name="公司"
                  type="email"
                  class="form-control"
                  :class="{ 'is-invalid': errors['公司'] }"
                  placeholder="請輸入公司"
                  rules="required"
                  v-model="form.company"
                />
                <ErrorMessage name="公司" class="invalid-feedback" />
              </div>
              <div class="mb-3 mb-lg-5 col-12">
                <label for="email" class="form-label"
                  >Email<span class="text-primary">*</span></label
                >
                <VField
                  id="email"
                  name="Email"
                  type="email"
                  class="form-control"
                  :class="{ 'is-invalid': errors['Email'] }"
                  placeholder="請輸入Email"
                  rules="email|required"
                  v-model="form.email"
                />
                <ErrorMessage name="Email" class="invalid-feedback" />
              </div>
              <div class="mb-3 mb-lg-5 col-12">
                <label for="phone" class="form-label"
                  >電話<span class="text-primary">*</span></label
                >
                <VField
                  id="phone"
                  name="電話"
                  type="phone"
                  class="form-control"
                  :class="{ 'is-invalid': errors['電話'] }"
                  placeholder="請輸入09或室內電話"
                  rules="min:8|max:10|required"
                  v-model="form.phone"
                ></VField>
                <ErrorMessage name="電話" class="invalid-feedback" />
              </div>
              <div class="mb-3 col-12">
                <label for="email" class="form-label"
                  >詢問類別<span class="text-primary">*</span></label
                >
                <div
                  class="form-check cursor-pointer me-3 mb-1"
                  v-for="option in types"
                  :key="option.id"
                >
                  <VField
                    type="radio"
                    name="類別"
                    :id="option.id"
                    :value="option.id"
                    v-model="form.category"
                    class="form-check-input cursor-pointer"
                    rules="required"
                  />
                  <label class="form-check-label cursor-pointer fw-light" :for="option.id">
                    {{ option.name }}
                  </label>
                </div>
                <div class="form-label category-err">{{ errors.類別 }}</div>
              </div>
              <div class="mb-5">
                <label for="content" class="form-label">描述</label>
                <textarea
                  id="content"
                  cols="30"
                  rows="2"
                  class="form-control fixed-textarea"
                  placeholder="在此輸入您的評論"
                  v-model="form.content"
                ></textarea>
              </div>
              <div class="col-12">
                <div class="text-center">
                  <vue-recaptcha
                    :sitekey="v2Sitekey"
                    size="normal"
                    theme="light"
                    hl="zh-TW"
                    @verify="recaptchaVerified"
                    @expire="recaptchaExpired"
                    @fail="recaptchaFailed"
                    ref="vueRecaptcha"
                  >
                  </vue-recaptcha>
                  <button
                    type="submit"
                    class="btn btn-secondary text-white rounded-0 fs-5 px-4 mt-4"
                  >
                    提交
                  </button>
                </div>
              </div>
            </div>
          </VForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiCreateMail } from "@/api";
import { apiCreateRECAPTCHA } from "@/api";
import vueRecaptcha from "vue3-recaptcha2";

export default {
  name: "contactMessage",
  components: {
    vueRecaptcha,
  },
  data() {
    return {
      resRecaptcha: null,
      v2Sitekey: process.env.VUE_APP_RECAPTCHA,
      sendMail: "callcenter-service@hpicorp.com.tw",
      isButtonDisabled: false,
      form: {
        name: "",
        company: "",
        phone: "",
        email: "",
        category: "",
        content: "",
      },
      types: [
        { id: 0, name: "委外/駐點/維護" },
        { id: 1, name: "雲端運算系統" },
        { id: 2, name: "企業資訊系統整合與開發" },
        { id: 3, name: "資訊設備詢價/租賃/節費" },
        { id: 4, name: "產品介紹" },
        { id: 5, name: "其他" },
      ],
    };
  },

  methods: {
    //recaptcha v2
    async recaptchaVerified(res) {
      try {
        this.resRecaptcha = await apiCreateRECAPTCHA(`g-recaptcha-response=${res}`);
        //驗證成功
        if (this.resRecaptcha.data.code === 9005) {
          alert(this.resRecaptcha.data.message);
          this.$refs.vueRecaptcha.reset();
        }
      } catch (error) {
        console.error("Error recaptchaVerified", error);
      }
    },
    //recaptcha 過期後執行
    recaptchaExpired() {
      console.log("驗證過期");
      this.resRecaptcha = null;
      this.$refs.vueRecaptcha.reset();
    },
    //recaptcha 失敗執行
    recaptchaFailed() {
      console.log("驗證失敗");
      this.resRecaptcha = null;
      this.$refs.vueRecaptcha.reset();
    },
    //清空表單
    clearForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      });
    },
    //傳送訊息API;
    async requestOrder() {
      try {
        if (this.resRecaptcha.data.code === 200) {
          const res = await apiCreateMail(this.form);
          alert(res.data.message);
          this.isButtonDisabled = true;
          //清空表單
          this.$nextTick(() => {
            this.$refs.form.resetForm();
            this.clearForm();
          });
          //清除recaptcha
          this.resRecaptcha = null;
          this.$refs.vueRecaptcha.reset();
          //防抖
          setTimeout(() => {
            this.isButtonDisabled = false;
          }, 1000);
        }
      } catch (err) {
        alert("機器人請驗證");
      }
    },
  },
};
</script>

<style scoped>
.category-err {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #f15e66;
  font-weight: 400;
}

.fixed-textarea {
  width: 100%;
  height: 8rem;
  resize: none;
}
iframe {
  width: 400px;
  height: 580px;
  border: none; /* 去掉邊框 */
}
</style>
